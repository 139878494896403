import { Heading, Stack } from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Flex } from 'reflexbox'
import { usePermissionsForScope } from 'store/permissions/hooks'
import { useScopes } from 'store/scopes/hooks'
import PermissionCreate from './Create'
import PermissionListItem from './ListItem'

const filterDefaultValues = {
  id: '',
  scopeId: '',
}

function PermissionList() {
  const scopes = useScopes()

  const scopeOptions = useMemo(() => {
    return zipObject(
      scopes.allIds,
      scopes.allIds.map((id) => get(scopes.byId[id], 'description'))
    )
  }, [scopes.allIds, scopes.byId])

  const filterForm = useForm({
    defaultValues: filterDefaultValues,
  })

  const filterValues = filterForm.watch()

  const permissions = usePermissionsForScope(filterValues.scopeId)

  const filteredPermissionIds = useMemo(() => {
    const idRegex = new RegExp(
      `^.*${filterValues.id.split('').join('.*')}.*$`,
      'i'
    )
    return permissions.allIds.filter((id) => idRegex.test(id))
  }, [filterValues.id, permissions.allIds])

  return (
    <Stack spacing={3}>
      <Flex
        mb={3}
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <Heading as="h2">Permissions</Heading>
        </Box>

        <Box>
          <PermissionCreate />
        </Box>
      </Flex>

      <Form form={filterForm} isInline spacing={4} my={2}>
        <Box minWidth="200px">
          <FormSelect
            name="scopeId"
            label={`Select Scope`}
            options={scopeOptions}
          />
        </Box>
        <Box>
          <FormInput name="id" label={`Filter by ID`} />
        </Box>
      </Form>

      {filteredPermissionIds.map((permissionId) => (
        <PermissionListItem key={permissionId} permissionId={permissionId} />
      ))}
    </Stack>
  )
}

export default PermissionList
