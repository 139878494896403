import {
  Badge,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { usePermission } from 'store/permissions/hooks'
import {
  useRoutePermission,
  useRoutePermissionsForService,
  useRouteServices,
} from 'store/routePermissions/hooks'
import RoutePermissionCreate from './Modal/Create'
import RoutePermissionDelete from './Modal/Delete'
import RoutePermissionEdit from './Modal/Edit'

function RoutePermissionListItem({ routePermissionId, ...props }) {
  const data = useRoutePermission(routePermissionId)

  const permission = usePermission(get(data, 'requiredPermissionId'))

  if (!data) {
    return null
  }

  return (
    <Box
      key={routePermissionId}
      p={5}
      boxShadow="md"
      borderWidth="1px"
      {...props}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={1}>
          <Box>
            <Badge variantColor="blue">{get(data, 'service')}</Badge>
          </Box>
          <Heading as="h4" fontSize={4}>
            {get(data, 'requestMethod')} {get(data, 'route')}
          </Heading>
          <Box>
            Permission:{' '}
            <Text as="span" fontSize={2} fontWeight="bold">
              {get(data, 'requiredPermissionId')}
            </Text>{' '}
            <Text as="span" fontSize={2}>
              ( {get(permission, 'description')} )
            </Text>
          </Box>
        </Stack>

        <Stack isInline>
          <RoutePermissionEdit routePermissionId={routePermissionId} />
          <RoutePermissionDelete routePermissionId={routePermissionId} />
        </Stack>
      </Flex>
    </Box>
  )
}

function RoutePermissionList() {
  const services = useRouteServices()
  const serviceOptions = useMemo(() => {
    return services.reduce((options, service) => {
      return options.concat({ value: service, label: service })
    }, [])
  }, [services])

  const [service, setService] = useState(null)
  const routePermissions = useRoutePermissionsForService(service)

  return (
    <Stack spacing={3}>
      <Flex
        mb={3}
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Box flexGrow={1} mr={2}>
          <Heading as="h2">Route Permissions</Heading>
        </Box>

        <Box>
          <RoutePermissionCreate />
        </Box>
      </Flex>

      <Stack isInline>
        <FormControl minW="200px">
          <FormLabel>Service</FormLabel>
          <Select
            options={serviceOptions}
            value={{ value: service, label: service }}
            onChange={({ value }) => setService(value)}
          />
        </FormControl>
      </Stack>

      <Stack spacing={2}>
        {routePermissions.allIds.map((id) => (
          <RoutePermissionListItem key={id} routePermissionId={id} />
        ))}
      </Stack>
    </Stack>
  )
}

export default RoutePermissionList
