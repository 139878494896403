import {
  Button,
  Collapse,
  FormControl,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/Select'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import usePagination from 'hooks/usePagination'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { getAllRoles } from 'store/roles'
import { fetchUserByRolePage } from 'store/users'
import { emptyArray, emptyObject } from 'utils/defaults'
import { getLinkWithPageQuery } from 'utils/getLinkWithPageQuery'
import * as Yup from 'yup'

function RoleSelector({ onChange }) {
  const roles = useSelector((state) => state.roles)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllRoles())
  }, [dispatch])

  const roleOptions = useMemo(() => {
    return zipObject(
      roles.allIds,
      roles.allIds.map((id) => roles.byId[id].name)
    )
  }, [roles.allIds, roles.byId])

  const defaultValues = useMemo(() => ({ roleId: null }), [])

  const validationSchema = useMemo(
    () => Yup.object({ roleId: Yup.mixed() }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const onSubmit = useCallback(
    ({ roleId }) => {
      onChange(roleId)
    },
    [onChange]
  )

  const roleId = form.watch('roleId')

  return (
    <Box>
      <Form form={form} onSubmit={onSubmit}>
        <Flex
          mb={3}
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Box sx={{ flexGrow: 1, mr: 2 }}>
            <FormSelect name="roleId" label={'Role'} options={roleOptions} />
          </Box>
          <Box>
            <FormControl>
              <Button
                type="submit"
                isDisabled={!roleId || form.formState.isSubmitting}
              >
                Go
              </Button>
            </FormControl>
          </Box>
        </Flex>
      </Form>
    </Box>
  )
}

function UsersByRolePage() {
  const history = useHistory()

  const [roleId, setRoleId] = useState(null)

  const pagination = useSelector((state) => state.pagination.userByRole)
  const users = useSelector((state) => state.users)

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      history.push(getLinkWithPageQuery(window.location, null))
      dispatch(fetchUserByRolePage(...args))
    },
    [dispatch, history]
  )

  const [page] = usePagination(
    pagination,
    fetchPage,
    { roleId, length: 10 },
    { paused: !roleId }
  )

  const [openedUser, setOpenedUser] = useState(null)

  return (
    <>
      <Heading as="h2" fontSize="2em" mb={3}>
        Users
      </Heading>

      <RoleSelector onChange={setRoleId} />

      <Stack>
        {get(pagination.pages[page], `itemIds`, emptyArray)
          .map((id) => get(users.byId, id, emptyObject))
          .map((user) => (
            <Box
              key={user.id}
              sx={{
                p: 5,
                boxShadow: 'md',
                borderWidth: '1px',
              }}
              onClick={() => setOpenedUser(user.id)}
            >
              <Flex
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Text>
                    <Text as="strong">ID:</Text> {user.id}
                  </Text>
                  <Text>
                    <Text as="strong">Name:</Text> {user.firstName}{' '}
                    {user.lastName}
                  </Text>
                  <Text>
                    <Text as="strong">Phone:</Text> {user.phone}
                  </Text>
                  <Text>
                    <Text as="strong">Email:</Text> {user.email}
                  </Text>
                </Box>
              </Flex>
              <Collapse isOpen={openedUser === user.id}></Collapse>
            </Box>
          ))}

        <PageBasedSwitcher
          pageIndex={page}
          totalPages={pagination.totalPages}
          totalItems={pagination.totalItems}
        />
      </Stack>
    </>
  )
}

export default UsersByRolePage
