import { api } from 'api'
import { batch } from 'react-redux'

export const USER_ADD = 'USER_ADD'
export const USER_ADD_BULK = 'USER_ADD_BULK'
export const USER_ROLES_SET = 'USER_ROLES_SET'
export const USER_SCOPES_SET = 'USER_SCOPES_SET'

export const USER_BY_ROLE_PAGE_ADD = 'USER_BY_ROLE_PAGE_ADD'
export const USER_BY_ROLE_PAGE_REMOVE = 'USER_BY_ROLE_PAGE_REMOVE'
export const USER_BY_ROLE_PAGE_REQUEST = 'USER_BY_ROLE_PAGE_REQUEST'
export const USER_BY_ROLE_PAGINATION_PURGE = 'USER_BY_ROLE_PAGINATION_PURGE'

export const getUserById = (userId) => async (dispatch) => {
  const url = `/v0/users/${userId}?include=[roles,scopes]`

  const { data } = await api(url)

  dispatch({ type: USER_ADD, data })
  dispatch({
    type: USER_ROLES_SET,
    userId,
    data: { items: data.roles, totalItems: data.roles.length },
  })
  dispatch({
    type: USER_SCOPES_SET,
    userId,
    data: { items: data.scopes, totalItems: data.scopes.length },
  })
}

export const setUserRolesById = (userId, body) => async (dispatch) => {
  const { data } = await api(`PUT /v0/users/${userId}/roles`, {
    body,
  })

  dispatch({ type: USER_ROLES_SET, data, userId })
}

export const revokeUserRolesByUserId = (userId) => async (dispatch) => {
  await api(`POST /v0/users/${userId}/roles/revoke`);

  dispatch({
    type: USER_ROLES_SET,
    data: {
      items: [],
      totalItems: 0
    },
    userId
  })
}

export const setUserScopesById = (userId, { scopeIds }) => async (dispatch) => {
  const { data } = await api(`PUT /v0/users/{userId}/scopes`, {
    userId,
    scopeIds,
  })

  dispatch({ type: USER_SCOPES_SET, data, userId })
}

export const getUserByEmail = (email) => async (dispatch) => {
  const { data } = await api(`/v0/users/email/{email}{?include}`, {
    email,
    include: '[roles,scopes]',
  })

  if (data && data.id) {
    dispatch({ type: USER_ADD, data })
    dispatch({
      type: USER_ROLES_SET,
      userId: data.id,
      data: { items: data.roles, totalItems: data.roles.length },
    })
    dispatch({
      type: USER_SCOPES_SET,
      userId: data.id,
      data: { items: data.scopes, totalItems: data.scopes.length },
    })
  }

  return data
}

export const getUserByPhone = (userPhone) => async (dispatch) => {
  const url = `/v0/users/phone/${userPhone}?include=[roles,scopes]`

  const { data } = await api(url)

  if (data && data.id) {
    dispatch({ type: USER_ADD, data })
    dispatch({
      type: USER_ROLES_SET,
      userId: data.id,
      data: { items: data.roles, totalItems: data.roles.length },
    })
    dispatch({
      type: USER_SCOPES_SET,
      userId: data.id,
      data: { items: data.scopes, totalItems: data.scopes.length },
    })
  }

  return data
}

export const fetchUserByRolePage = (
  { page = 1, length, roleId },
  queryHash
) => async (dispatch) => {
  dispatch({ type: USER_BY_ROLE_PAGE_REQUEST, page, queryHash })

  try {
    const { data } = await api(`/v0/roles/{roleId}/users{?page,length}`, {
      page,
      roleId,
      length,
    })

    batch(() => {
      dispatch({ type: USER_ADD_BULK, data })
      dispatch({ type: USER_BY_ROLE_PAGE_ADD, page, data, queryHash })
    })

    return data
  } catch (err) {
    if (err.error && err.error.status >= 500) {
      dispatch({ type: USER_BY_ROLE_PAGE_REMOVE, page, queryHash })
    }
  }
}
