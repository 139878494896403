import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RoleList from './List'
import RoleView from './View'

function RolesPage() {
  return (
    <Switch>
      <Route exact path="/dash/roles" component={RoleList} />
      <Route path="/dash/roles/:roleId" component={RoleView} />
    </Switch>
  )
}

export default RolesPage
