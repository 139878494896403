import { ShopUp } from '@shopuptech/api-client'

export const client = ShopUp({
  baseUrl: '/api/auth',
  request: {
    fetch: window.fetch,
  },
})

export const api = client.request
