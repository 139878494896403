import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormMultiSelect from 'components/Form/MultiSelect'
import { get, zipObject } from 'lodash-es'
import ScopeListItem from 'pages/scopes/ListItem'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createFilter } from 'react-select'
import { Box } from 'reflexbox'
import { getScopesForRole, setScopesForRole } from 'store/roles'
import { getAllScopes } from 'store/scopes'
import { emptyArray } from 'utils/defaults'
import * as Yup from 'yup'

const getDefaultValues = (roleScopeIds) => ({
  scopeIds: roleScopeIds,
})

const getValidationSchema = () => {
  return Yup.object({
    scopeIds: Yup.array().of(Yup.string()),
  })
}

function RoleScopesEdit({ roleId }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllScopes())
  }, [dispatch, roleId])

  const role = useSelector((state) => state.roles.byId[roleId])

  const roleScopeIds = useSelector((state) =>
    get(state.roleScope.byRole, roleId, emptyArray)
  )

  const scopes = useSelector((state) => state.scopes)

  const scopeOptions = useMemo(() => {
    return zipObject(
      scopes.allIds,
      scopes.allIds.map((id) => get(scopes.byId[id], 'description', id))
    )
  }, [scopes.allIds, scopes.byId])

  const getFilterOption = useCallback(
    (selectedScopeIds) => (...args) => {
      return (
        !selectedScopeIds.includes(args[0].value) && createFilter()(...args)
      )
    },
    []
  )

  const defaultValues = useMemo(() => getDefaultValues(roleScopeIds), [
    roleScopeIds,
  ])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    if (isOpen) {
      formReset(defaultValues)
    }
  }, [defaultValues, formReset, isOpen])

  const onSubmit = useCallback(
    async ({ scopeIds }) => {
      try {
        await dispatch(setScopesForRole(roleId, { scopeIds }))
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, roleId, toast]
  )

  const selectedScopeIds = form.watch('scopeIds')

  return (
    <Authorize permissions="auth:RoleScope:set">
      <Button onClick={onOpen}>Update</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Form form={form} onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Scopes for {get(role, 'name')}</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <FormMultiSelect
                name="scopeIds"
                label={`Scopes`}
                options={scopeOptions}
                filterOption={getFilterOption(selectedScopeIds)}
                isClearable={false}
              />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit">Save</Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

function RoleScopesTab({ roleId }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getScopesForRole(roleId))
  }, [dispatch, roleId])

  const roleScopeIds = useSelector((state) =>
    get(state.roleScope.byRole, roleId, emptyArray)
  )

  return (
    <Stack spacing={5}>
      <Box>
        {roleScopeIds.map((scopeId) => (
          <ScopeListItem key={scopeId} scopeId={scopeId} />
        ))}
      </Box>

      <Box>
        <RoleScopesEdit roleId={roleId} />
      </Box>
    </Stack>
  )
}

export default RoleScopesTab
