import { Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import RoleListItem from 'pages/roles/ListItem'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'reflexbox'
import { getRolesForPermission } from 'store/permissions'
import { emptyArray } from 'utils/defaults'

function PermissionRolesTab({ permissionId }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRolesForPermission(permissionId))
  }, [dispatch, permissionId])

  const permissionRoleIds = useSelector((state) =>
    get(state.rolePermission.byPermission, permissionId, emptyArray)
  )

  return (
    <Stack spacing={5}>
      <Box>
        {permissionRoleIds.map((roleId) => (
          <RoleListItem key={roleId} roleId={roleId} />
        ))}
      </Box>
    </Stack>
  )
}

export default PermissionRolesTab
