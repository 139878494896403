import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { deleteRoutePermission } from 'store/routePermissions'
import { useRoutePermission } from 'store/routePermissions/hooks'

function RoutePermissionDelete({ routePermissionId, ...props }) {
  const routePermission = useRoutePermission(routePermissionId)

  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const toast = useToast()

  const dispatch = useDispatch()
  const onSubmit = useCallback(async () => {
    try {
      await dispatch(deleteRoutePermission(routePermissionId))
      onClose()
    } catch (err) {
      handleAPIError(err, { toast })
    }
  }, [dispatch, onClose, routePermissionId, toast])

  return (
    <Authorize permissions="">
      <Button {...props} variantColor="red" onClick={onOpen}>
        Delete
      </Button>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Route Permission
          </AlertDialogHeader>

          <AlertDialogBody>
            <Heading as="h4" fontSize={3} mb={2}>
              <Badge variantColor="blue" mr={2}>
                {get(routePermission, 'service')}
              </Badge>
              {get(routePermission, 'requestMethod')}{' '}
              {get(routePermission, 'route')}
            </Heading>

            <Text fontSize={3} mb={2}>
              Are you sure? You can't undo this action afterwards.
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button variantColor="red" onClick={onSubmit} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Authorize>
  )
}

export default RoutePermissionDelete
