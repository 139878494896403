import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getPermission } from 'store/permissions'
import PermissionRolesTab from './Tabs/Roles'

function PermissionView() {
  const { permissionId } = useParams()

  const permission = useSelector(
    (state) => state.permissions.byId[permissionId]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!permission && permissionId) {
      dispatch(getPermission(permissionId))
    }
  }, [dispatch, permission, permissionId])

  return (
    <>
      <Heading as="h2" mb={5}>
        <Text as="span" fontStyle="italic">
          Permission:
        </Text>{' '}
        {get(permission, 'id')}
      </Heading>

      <Tabs>
        <TabList mb={5}>
          <Tab>Roles</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PermissionRolesTab permissionId={permissionId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default PermissionView
