import { map } from 'lodash-es'
import { ROLE_SCOPES_SET } from 'store/roles'
import { SCOPE_ROLES_SET } from 'store/scopes'
import { emptyObject } from 'utils/defaults'

const initialState = {
  byRole: emptyObject,
  byScope: emptyObject,
}

const roleScopeReducer = (
  state = initialState,
  { type, data, roleId, scopeId }
) => {
  switch (type) {
    case SCOPE_ROLES_SET:
      return {
        byRole: initialState.byRole,
        byScope: {
          ...state.byScope,
          [scopeId]: map(data.items, 'id'),
        },
      }
    case ROLE_SCOPES_SET:
      return {
        byRole: {
          ...state.byRole,
          [roleId]: map(data.items, 'id'),
        },
        byScope: initialState.byScope,
      }
    default:
      return state
  }
}

export default roleScopeReducer
