import { FormControl, FormLabel } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'
import ErrorMessage from './ErrorMessage'

function SelectField({
  id,
  name,
  options,
  isLoading,
  value,
  setValue,
  register,
  Component,
  ...props
}) {
  const formattedOptions = useMemo(() => {
    return Object.entries(options).reduce((options, [value, label]) => {
      return options.concat({ value, label })
    }, [])
  }, [options])

  const formattedValue = useMemo(() => {
    return value ? { value, label: options[value] } : null
  }, [options, value])

  const onChange = useCallback(
    (value) => {
      setValue(name, value ? value.value : null)
    },
    [name, setValue]
  )

  return (
    <Component
      {...props}
      id={id}
      name={name}
      value={formattedValue}
      options={formattedOptions}
      onChange={onChange}
      ref={register}
    />
  )
}

export function FormSelect({
  name,
  id = name,
  label,
  labelProps,
  options,
  loading,
  required,
  validate,
  SelectComponent = ReactSelect,
  ...props
}) {
  const { register, errors, watch, setValue } = useFormContext()

  const value = watch(name)

  return (
    <FormControl
      isRequired={required}
      isDisabled={props.disabled}
      isInvalid={Boolean(get(errors, name))}
      isReadOnly={props.readonly}
    >
      <FormLabel
        htmlFor={id}
        display={label ? 'block' : 'none'}
        {...(labelProps && { ...labelProps })}
      >
        {label}
      </FormLabel>

      <SelectField
        {...props}
        id={id}
        name={name}
        options={options}
        isLoading={loading}
        value={value}
        setValue={setValue}
        register={() => register({ name, required, validate })}
        Component={SelectComponent}
      />

      <ErrorMessage name={name} />
    </FormControl>
  )
}

export default FormSelect
