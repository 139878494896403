import { Button, Heading, Text } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import PermissionEdit from './Edit'

function PermissionListItem({ permissionId }) {
  const permission = useSelector(
    (state) => state.permissions.byId[permissionId]
  )

  return permission ? (
    <Box
      key={permissionId}
      sx={{
        p: 5,
        boxShadow: 'md',
        borderWidth: '1px',
        mb: 2,
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Heading as="h4" fontSize={4}>
            {get(permission, 'id')}
          </Heading>
          <Text>{get(permission, 'description')}</Text>
        </Box>

        <Box>
          <PermissionEdit permissionId={permissionId} />

          <Button as={Link} to={`/dash/permissions/${permissionId}`}>
            Open
          </Button>
        </Box>
      </Flex>
    </Box>
  ) : null
}

export default PermissionListItem
