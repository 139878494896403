import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPermissions, getPermission } from '.'

export function usePermissionsForScope(scopeId) {
  const { byId, allIds } = useSelector((state) => state.permissions)

  const permissions = useMemo(() => {
    return {
      byId,
      allIds: allIds.filter((id) => byId[id]?.scopeId === scopeId),
    }
  }, [allIds, byId, scopeId])

  const dispatch = useDispatch()

  useEffect(() => {
    if (scopeId) {
      dispatch(getAllPermissions({ scopeId }))
    }
  }, [dispatch, scopeId])

  return permissions
}

export function usePermissions() {
  const permissions = useSelector((state) => state.permissions)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPermissions())
  }, [dispatch])

  return permissions
}

export function usePermission(permissionId) {
  const data = useSelector((state) => state.permissions.byId[permissionId])

  const dispatch = useDispatch()
  useEffect(() => {
    if (!data && permissionId) {
      dispatch(getPermission(permissionId))
    }
  }, [data, dispatch, permissionId])

  return data
}
