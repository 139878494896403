import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { usePermissionsForScope } from 'store/permissions/hooks'
import { updateRoutePermission } from 'store/routePermissions'
import { useRoutePermission } from 'store/routePermissions/hooks'
import { useScopes } from 'store/scopes/hooks'
import { parsePermissionId } from 'utils/parsePermissionId'
import * as Yup from 'yup'

const getDefaultValues = (routePermission) => {
  const { scope } = parsePermissionId(
    get(routePermission, 'requiredPermissionId', '')
  )
  return {
    route: get(routePermission, 'route'),
    scopeId: scope,
    requiredPermissionId: get(routePermission, 'requiredPermissionId'),
  }
}

const getValidationSchema = () =>
  Yup.object({
    route: Yup.string().required(`required`),
    scopeId: Yup.string().required(`required`),
    requiredPermissionId: Yup.string().required(`required`),
  })

function RoutePermissionEdit({ routePermissionId, ...props }) {
  const routePermission = useRoutePermission(routePermissionId)

  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(routePermission), [
    routePermission,
  ])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ route, requiredPermissionId }) => {
      try {
        await dispatch(
          updateRoutePermission(routePermissionId, {
            route,
            requiredPermissionId,
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, routePermissionId, toast]
  )

  const scopes = useScopes()
  const scopeOptions = useMemo(() => {
    return zipObject(
      scopes.allIds,
      scopes.allIds.map((id) => get(scopes.byId[id], 'description'))
    )
  }, [scopes.allIds, scopes.byId])

  const scopeId = form.watch('scopeId')

  const formSetValue = form.setValue
  useEffect(() => {
    formSetValue('requiredPermissionId', '')
  }, [formSetValue, scopeId])

  const permissions = usePermissionsForScope(scopeId)
  const permissionOptions = useMemo(() => {
    return zipObject(
      permissions.allIds,
      permissions.allIds.map((id) =>
        get(permissions.byId[id], 'description', id)
      )
    )
  }, [permissions.allIds, permissions.byId])

  const formReset = form.reset
  useEffect(() => {
    formReset(getDefaultValues(routePermission))
  }, [formReset, routePermission])

  return (
    <Authorize permissions="">
      <Button {...props} onClick={onOpen}>
        Edit
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Form form={form} onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Edit Route Permission: {routePermissionId}
            </ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <Stack>
                <FormInput name="route" label={`Route`} />

                <Box>
                  <FormSelect
                    name="scopeId"
                    label={`Permission Scope`}
                    options={scopeOptions}
                  />
                </Box>

                <Box>
                  <FormSelect
                    name="requiredPermissionId"
                    label={`Required Permission`}
                    options={permissionOptions}
                  />
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <FormButton type="submit">Save</FormButton>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

export default RoutePermissionEdit
