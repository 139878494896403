import { keyBy, map, union } from 'lodash-es'
import { ROLE_ADD, ROLE_ADD_BULK, ROLE_SET_ALL, ROLE_UPDATE } from 'store/roles'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
}

const rolesReducer = (state = initialState, { type, data, roleId: id }) => {
  switch (type) {
    case ROLE_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: { ...data },
        },
        allIds: union(state.allIds, [data[idKey]]),
      }
    case ROLE_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: {
            ...data,
          },
        },
      }
    case ROLE_SET_ALL:
      return {
        ...state,
        byId: keyBy(data.items, idKey),
        allIds: map(data.items, idKey),
      }
    case ROLE_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
      }
    default:
      return state
  }
}

export default rolesReducer
