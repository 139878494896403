import {
  Box,
  Button,
  Flex,
  Grid,
  SimpleGrid,
  Heading,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
  useToast,
  Code,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRoles } from 'store/roles'
import { getAllScopes } from 'store/scopes'
import { getUserByEmail, getUserById, getUserByPhone } from 'store/users'
import * as Yup from 'yup'
import UserRoles from './UserRoles'
import UserScopes from './UserScopes'
import RoleCreate from 'pages/roles/Create'
import CreateUser from '../CreateUser'

const defaultValues = {
  byId: {
    userId: '',
  },
  byEmail: {
    email: '',
  },
  byPhone: {
    phone: '',
  },
}

const validationSchema = {
  byId: Yup.object({
    userId: Yup.number().integer().required(`required`),
  }),
  byEmail: Yup.object({
    email: Yup.string().email().required(`required`),
  }),
  byPhone: Yup.object({
    phone: Yup.string()
      .matches(/^01\d{9}$/, 'expected format 01XXXXXXXXX')
      .required(`required`),
  }),
}

function FindUserById({ setUserId }) {
  const toast = useToast()

  const form = useForm({
    defaultValues: defaultValues.byId,
    validationSchema: validationSchema.byId,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ userId }) => {
      try {
        await dispatch(getUserById(userId))
        setUserId(userId)
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, setUserId, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <FormInput
          name="userId"
          type="number"
          label={'Find By User ID'}
          InputRight={
            <InputRightElement width="4.5rem">
              <Button type="submit" height="1.75rem" size="sm">
                Find
              </Button>
            </InputRightElement>
          }
        />
      </Stack>
    </Form>
  )
}

function FindUserByEmail({ setUserId }) {
  const toast = useToast()

  const form = useForm({
    defaultValues: defaultValues.byEmail,
    validationSchema: validationSchema.byEmail,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        const user = await dispatch(getUserByEmail(email))
        console.log(user)
        setUserId(get(user, 'id', null))
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, setUserId, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <FormInput
          name="email"
          label={'Find By Email'}
          InputRight={
            <InputRightElement width="4.5rem">
              <Button type="submit" height="1.75rem" size="sm">
                Find
              </Button>
            </InputRightElement>
          }
        />
      </Stack>
    </Form>
  )
}

function FindUserByPhone({ setUserId }) {
  const form = useForm({
    defaultValues: defaultValues.byPhone,
    validationSchema: validationSchema.byPhone,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ phone }) => {
      const user = await dispatch(getUserByPhone(`88${phone}`))
      setUserId(get(user, 'id', null))
    },
    [dispatch, setUserId]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <FormInput
          name="phone"
          label={'Find By Phone'}
          InputLeft={<InputLeftAddon children="88" />}
          InputRight={
            <InputRightElement width="4.5rem">
              <Button type="submit" height="1.75rem" size="sm">
                Find
              </Button>
            </InputRightElement>
          }
        />
      </Stack>
    </Form>
  )
}

function FindUserPage() {
  const dispatch = useDispatch()

  const [userId, setUserId] = useState(null)

  useEffect(() => {
    dispatch(getAllRoles())
    dispatch(getAllScopes())
  }, [dispatch])

  const user = useSelector((state) => get(state.users.byId, userId))
  const userToken = useMemo(() => {
    if (!user) return null
    return user.userTokens ? user.userTokens[0] : null
  }, [user])

  console.log(userToken)

  return (
    <Stack spacing={3}>
      <SimpleGrid columns={2}>
        <Box>
          <Heading as="h2">Find User</Heading>
        </Box>
        <Box>
          <CreateUser setUserId={setUserId} />
        </Box>
      </SimpleGrid>

      <Box borderWidth="1px" p={2}>
        <Flex
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <FindUserById setUserId={setUserId} />

          <FindUserByEmail setUserId={setUserId} />

          <FindUserByPhone setUserId={setUserId} />
        </Flex>
      </Box>

      {user && (
        <>
          {user.userType === 'SYSTEM_USER' ? (
            <Box style={{ marginTop: '24px', marginBottom: '24px' }}>
              <Stack>
                <Text as="strong" color="#e53e3e">
                  SYSTEM USER
                </Text>
                <Code colorScheme="red" children={`token:${userToken}`} />
              </Stack>
            </Box>
          ) : null}
          <Box borderWidth="1px" p={3}>
            <Stack>
              <Text>
                <Text as="strong">ID:</Text> {get(user, 'id')}
              </Text>
              <Text>
                <Text as="strong">Name:</Text>{' '}
                {`${get(user, 'firstName')} ${get(user, 'lastName')}`}
              </Text>
              <Text>
                <Text as="strong">Phone:</Text> {get(user, 'phone')}
              </Text>
              <Text>
                <Text as="strong">Email:</Text> {get(user, 'email')}
              </Text>

              <UserRoles userId={userId} />

              <UserScopes userId={userId} />
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  )
}

export default FindUserPage
