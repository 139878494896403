import { useDisclosure } from '@chakra-ui/core'
import Sidebar from 'components/Sidebar'
import Topbar from 'components/Topbar'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import PermissionsPage from './permissions'
import RolesPage from './roles'
import RoutePermissionsPage from './route-permissions'
import ScopesPage from './scopes'
import UsersByRolePage from './users/by-role'
import FindUserPage from './users/find-user'

function Dashboard() {
  const { isOpen, onToggle } = useDisclosure(true)

  return (
    <>
      <Topbar />

      <Flex height="100%" sx={{ position: 'relative' }}>
        <Sidebar isOpen={isOpen} onToggle={onToggle} />

        <Box
          flexGrow="1"
          overflowY="scroll"
          width="auto"
          p={10}
          sx={{
            transition: '0.2s',
            height: ({ sizes }) => `calc(100% - ${sizes.navbar})`,
            ml: ({ sizes }) => (isOpen ? sizes.sidebar : '1rem'),
            mt: ({ sizes }) => sizes.navbar,
          }}
        >
          <Switch>
            <Route path="/dash/roles" component={RolesPage} />
            <Route path="/dash/permissions" component={PermissionsPage} />
            <Route path="/dash/scopes" component={ScopesPage} />
            <Route
              path="/dash/route-permissions"
              component={RoutePermissionsPage}
            />
            <Route path="/dash/users/find-user" component={FindUserPage} />
            <Route path="/dash/users/by-role" component={UsersByRolePage} />
          </Switch>
        </Box>
      </Flex>
    </>
  )
}

export default Dashboard
