import { keyBy, map, union, without } from 'lodash-es'
import {
  PERMISSION_ADD,
  PERMISSION_ADD_BULK,
  PERMISSION_UPDATE,
} from 'store/permissions'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
}

const permissionsReducer = (
  state = initialState,
  { type, data, permissionId: id }
) => {
  switch (type) {
    case PERMISSION_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data[idKey]]),
      }
    case PERMISSION_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: undefined,
          [data[idKey]]: {
            ...data,
          },
        },
        allIds: union(without(state.allIds, id), [data[idKey]]),
      }
    case PERMISSION_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
      }
    default:
      return state
  }
}

export default permissionsReducer
