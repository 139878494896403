import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormMultiSelect from 'components/Form/MultiSelect'
import { get, zipObject } from 'lodash-es'
import PermissionListItem from 'pages/permissions/ListItem'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createFilter } from 'react-select'
import { Box } from 'reflexbox'
import { getAllPermissions } from 'store/permissions'
import { getPermissionsForRole, setPermissionsForRole } from 'store/roles'
import { emptyArray } from 'utils/defaults'
import * as Yup from 'yup'

const getDefaultValues = (rolePermissionIds) => ({
  permissionIds: rolePermissionIds,
})

const getValidationSchema = () => {
  return Yup.object({
    permissionIds: Yup.array().of(Yup.string()),
  })
}

function RolePermissionsEdit({ roleId }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllPermissions())
  }, [dispatch])

  const role = useSelector((state) => state.roles.byId[roleId])

  const rolePermissionIds = useSelector((state) =>
    get(state.rolePermission.byRole, roleId, emptyArray)
  )

  const permissions = useSelector((state) => state.permissions)

  const permissionOptions = useMemo(() => {
    return zipObject(
      permissions.allIds,
      permissions.allIds.map((id) =>
        get(permissions.byId[id], 'description', id)
      )
    )
  }, [permissions.allIds, permissions.byId])

  const getFilterOption = useCallback(
    (selectedPermissionIds) => (...args) => {
      return (
        !selectedPermissionIds.includes(args[0].value) &&
        createFilter()(...args)
      )
    },
    []
  )

  const defaultValues = useMemo(() => getDefaultValues(rolePermissionIds), [
    rolePermissionIds,
  ])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    if (isOpen) {
      formReset(defaultValues)
    }
  }, [defaultValues, formReset, isOpen])

  const onSubmit = useCallback(
    async ({ permissionIds }) => {
      try {
        await dispatch(setPermissionsForRole(roleId, { permissionIds }))
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, roleId, toast]
  )

  const selectedPermissionIds = form.watch('permissionIds')

  return (
    <Authorize permissions="auth:RolePermission:set">
      <Button onClick={onOpen}>Update</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Form form={form} onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Update Permissions for {get(role, 'name')}{' '}
            </ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <FormMultiSelect
                name="permissionIds"
                label={`Permissions`}
                options={permissionOptions}
                filterOption={getFilterOption(selectedPermissionIds)}
                isClearable={false}
              />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit">Save</Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

function RolePermissionsTab({ roleId }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPermissionsForRole(roleId))
  }, [dispatch, roleId])

  const rolePermissionIds = useSelector((state) =>
    get(state.rolePermission.byRole, roleId, emptyArray)
  )

  return (
    <Stack spacing={5}>
      <Box>
        {rolePermissionIds.map((permissionId) => (
          <PermissionListItem key={permissionId} permissionId={permissionId} />
        ))}
      </Box>

      <Box>
        <RolePermissionsEdit roleId={roleId} />
      </Box>
    </Stack>
  )
}

export default RolePermissionsTab
