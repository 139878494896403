import { Stack } from '@chakra-ui/core'
import PermissionListItem from 'pages/permissions/ListItem'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'reflexbox'
import { getAllPermissions } from 'store/permissions'

function ScopePermissionsTab({ scopeId }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllPermissions())
  }, [dispatch])

  const scopeRegex = useMemo(() => {
    return new RegExp(`^${scopeId}:`)
  }, [scopeId])

  const scopePermissionIds = useSelector((state) =>
    state.permissions.allIds.filter((id) => scopeRegex.test(id))
  )

  return (
    <Stack spacing={5}>
      <Box>
        {scopePermissionIds.map((permissionId) => (
          <PermissionListItem key={permissionId} permissionId={permissionId} />
        ))}
      </Box>
    </Stack>
  )
}

export default ScopePermissionsTab
