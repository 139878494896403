import { api } from 'api'
import { batch } from 'react-redux'
import { PERMISSION_ADD_BULK } from 'store/permissions'

export const SCOPE_ADD = 'SCOPE_ADD'
export const SCOPE_UPDATE = 'SCOPE_UPDATE'
export const SCOPE_SET_ALL = 'SCOPE_SET_ALL'
export const SCOPE_PERMISSIONS_SET = 'SCOPE_PERMISSIONS_SET'
export const SCOPE_ROLES_SET = 'SCOPE_ROLES_SET'
export const SCOPE_USERS_SET = 'SCOPE_USERS_SET'

export const createScope = ({ id, description }) => async (dispatch) => {
  const { data } = await api('POST /v0/scopes', {
    id,
    description,
  })

  dispatch({ type: SCOPE_ADD, data })

  return data
}

export const updateScope = (scopeId, { description }) => async (dispatch) => {
  const { data } = await api('PUT /v0/scopes/{scopeId}', {
    scopeId,
    description,
  })

  dispatch({ type: SCOPE_UPDATE, data })

  return data
}

export const getScope = (scopeId) => async (dispatch) => {
  const { data } = await api('/v0/scopes/{scopeId}', {
    scopeId,
  })

  dispatch({ type: SCOPE_ADD, data })

  return data
}

export const getAllScopes = () => async (dispatch) => {
  const { data } = await api('/v0/scopes')

  dispatch({ type: SCOPE_SET_ALL, data })

  return data
}

export const getPermissionsForScope = (scopeId) => async (dispatch) => {
  const { data } = await api('/v0/scopes/{scopeId}/permissions', {
    scopeId,
  })

  batch(() => {
    dispatch({ type: SCOPE_PERMISSIONS_SET, data, scopeId })
    dispatch({ type: PERMISSION_ADD_BULK, data })
  })

  return data
}

export const getRolesForScope = (scopeId) => async (dispatch, getState) => {
  const { roleScope } = getState()

  if (roleScope.byScope[scopeId]) {
    return {
      items: roleScope.byScope[scopeId].map((id) => ({ id })),
    }
  }

  const { data } = await api('/v0/scopes/{scopeId}/roles', {
    scopeId,
  })

  dispatch({ type: SCOPE_ROLES_SET, data, scopeId })

  return data
}
