import { Button, Text } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'

function ScopeListItem({ scopeId }) {
  const scope = useSelector((state) => state.scopes.byId[scopeId])

  return (
    <Box
      sx={{
        p: 5,
        boxShadow: 'md',
        borderWidth: '1px',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Text>
            <Text as="strong">ID:</Text> {get(scope, 'id')}
          </Text>
          <Text>
            <Text as="strong">Description:</Text> {get(scope, 'description')}
          </Text>
        </Box>

        <Box>
          <Button as={Link} to={`/dash/scopes/${scopeId}`}>
            Open
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default ScopeListItem
