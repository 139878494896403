import { Heading, Stack } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { getAllScopes } from 'store/scopes'
import ScopeCreate from './Create'
import ScopeListItem from './ListItem'

function ScopeList() {
  const scopes = useSelector((state) => state.scopes)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllScopes())
  }, [dispatch])

  return (
    <Stack spacing={3}>
      <Flex
        mb={3}
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <Heading as="h2">Scopes</Heading>
        </Box>
        <Box>
          <ScopeCreate />
        </Box>
      </Flex>

      {scopes.allIds.map((scopeId) => (
        <ScopeListItem key={scopeId} scopeId={scopeId} />
      ))}
    </Stack>
  )
}

export default ScopeList
