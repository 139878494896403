import { combineReducers } from 'redux'
import { CURRENT_USER_REMOVE } from 'store/currentUser'
import user from './currentUser/reducer'
import errorBoundary from './errorBoundary/reducer'
import pagination from './pagination/reducer'
import permissions from './permissions/reducer'
import rolePermission from './rolePermission/reducer'
import roles from './roles/reducer'
import roleScope from './roleScope/reducer'
import routePermissions from './routePermissions/reducer'
import scopes from './scopes/reducer'
import users from './users/reducer'
import userScope from './userScope/reducer'

const reducer = combineReducers({
  user,
  errorBoundary,
  pagination,
  permissions,
  rolePermission,
  roles,
  roleScope,
  scopes,
  users,
  userScope,
  routePermissions,
})

export default (state, action) => {
  if (action.type === CURRENT_USER_REMOVE) {
    state = { errorBoundary: state.errorBoundary }
  }

  return reducer(state, action)
}
