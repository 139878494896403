import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormMultiSelect from 'components/Form/MultiSelect'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createFilter } from 'react-select'
import { Box } from 'reflexbox'
import { setUserRolesById } from 'store/users'
import { emptyArray } from 'utils/defaults'
import * as Yup from 'yup'
import FormButton from 'components/Form/Button'
import RevokeUserRolesButton from './RevokeUserRolesButton'

const getDefaultValues = (userRoleIds) => ({
  roleIds: userRoleIds,
})

const getValidationSchema = () => {
  return Yup.object({
    roleIds: Yup.array().of(Yup.string()),
  })
}

function UserRoles({ userId, ...props }) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const user = useSelector((state) => get(state.users.byId, userId))

  const roles = useSelector((state) => state.roles)

  const userRoleIds = useSelector((state) =>
    get(state.users.rolesById, userId, emptyArray)
  )

  const roleOptions = useMemo(() => {
    return zipObject(
      roles.allIds,
      roles.allIds.map((id) => roles.byId[id].name)
    )
  }, [roles.allIds, roles.byId])

  const getFilterOption = useCallback(
    (selectedRoleIds) => (...args) => {
      return (
        !selectedRoleIds.includes(Number(args[0].value)) &&
        createFilter()(...args)
      )
    },
    []
  )

  const defaultValues = useMemo(() => getDefaultValues(userRoleIds), [
    userRoleIds,
  ])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    if (isOpen) {
      formReset(defaultValues)
    }
  }, [defaultValues, formReset, isOpen])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ roleIds }) => {
      await dispatch(setUserRolesById(userId, { roleIds }))
    },
    [dispatch, userId]
  )

  const selectedRoleIds = form.watch('roleIds')

  return (
    <Box {...props}>
      <Text>
        <Text as="strong">Roles: </Text>
        <Button
          size="xs"
          variant="outline"
          variantColor="blue"
          onClick={onOpen}
        >
          Update
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>
                Assign Roles for {get(user, 'FULL_NAME')}
              </ModalHeader>

              <ModalCloseButton />

              <ModalBody>
                <FormMultiSelect
                  name="roleIds"
                  options={roleOptions}
                  filterOption={getFilterOption(selectedRoleIds)}
                  isClearable={false}
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  type="button"
                  variantColor="blue"
                  mr={3}
                  onClick={onClose}
                >
                  Close
                </Button>
                <FormButton type="submit" variantColor="green">
                  Assign Roles
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Text>
      <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>
        {userRoleIds.map((roleId) => (
          <div style={{ marginRight: '10px', marginTop: '10px' }}>
            <Tag key={roleId}>{get(roles.byId[roleId], 'name')}</Tag>
          </div>
        ))}
      </div>

      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <RevokeUserRolesButton userId={userId} />
      </div>
    </Box>
  )
}

export default UserRoles
