import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PermissionList from './List'
import PermissionView from './View'

function PermissionsPage() {
  return (
    <Switch>
      <Route exact path="/dash/permissions" component={PermissionList} />
      <Route
        path="/dash/permissions/:permissionId"
        component={PermissionView}
      />
    </Switch>
  )
}

export default PermissionsPage
