import { map } from 'lodash-es'
import { PERMISSION_ROLES_SET, PERMISSION_UPDATE } from 'store/permissions'
import { ROLE_PERMISSIONS_SET } from 'store/roles'
import { emptyObject } from 'utils/defaults'

const initialState = {
  byRole: emptyObject,
  byPermission: emptyObject,
}

const rolePermissionReducer = (
  state = initialState,
  { type, data, roleId, permissionId }
) => {
  switch (type) {
    case PERMISSION_ROLES_SET:
      return {
        byRole: initialState.byRole,
        byPermission: {
          ...state.byPermission,
          [permissionId]: map(data.items, 'id'),
        },
      }
    case ROLE_PERMISSIONS_SET:
      return {
        byRole: {
          ...state.byRole,
          [roleId]: map(data.items, 'id'),
        },
        byPermission: initialState.byPermission,
      }
    case PERMISSION_UPDATE:
      return permissionId !== data.id ? initialState : state
    default:
      return state
  }
}

export default rolePermissionReducer
