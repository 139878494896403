import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updatePermission } from 'store/permissions'
import { parsePermissionId } from 'utils/parsePermissionId'
import * as Yup from 'yup'

const getDefaultValues = (permission) => {
  const { resource, action } = parsePermissionId(get(permission, 'id', ''))

  return {
    resource,
    action,
    description: get(permission, 'description', ''),
  }
}

const getValidationSchema = () =>
  Yup.object({
    resource: Yup.string().required(`required`),
    action: Yup.string().required(`required`),
    description: Yup.string().required(`required`),
  })

function PermissionEdit({ permissionId }) {
  const permission = useSelector(
    (state) => state.permissions.byId[permissionId]
  )

  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(permission), [
    permission,
  ])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const reset = form.reset
  useEffect(() => {
    reset(getDefaultValues(permission))
  }, [reset, permission])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ action, resource, description }) => {
      try {
        const permissionId = get(permission, 'id')
        const scope = get(permission, 'scopeId')

        await dispatch(
          updatePermission(permissionId, {
            scope,
            action,
            resource,
            description,
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, permission, toast]
  )

  return (
    <Authorize permissions="auth:Permission:update">
      <Button onClick={onOpen}>Edit</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Form form={form} onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Permission: {permissionId}</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <FormInput name="resource" label={`Resource Name`} />
              <FormInput name="action" label={`Action Name`} />
              <FormInput name="description" label={`Description`} />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit" isDisabled={form.formState.isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

export default PermissionEdit
