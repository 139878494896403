import { api } from 'api'

export const ROUTE_PERMISSION_REMOVE = 'ROUTE_PERMISSION_REMOVE'
export const ROUTE_PERMISSION_ADD = 'ROUTE_PERMISSION_ADD'
export const ROUTE_PERMISSION_ADD_BULK = 'ROUTE_PERMISSION_ADD_BULK'
export const ROUTE_SERVICE_ADD_BULK = 'ROUTE_SERVICE_ADD_BULK'
export const ROUTE_PERMISSION_UPDATE = 'ROUTE_PERMISSION_UPDATE'

export const getRouteServices = () => async (dispatch) => {
  const { data } = await api('GET /v0/route-permissions/services')

  dispatch({ type: ROUTE_SERVICE_ADD_BULK, data })

  return data
}

export const createRoutePermission = ({
  service,
  route,
  requestMethod,
  requiredPermissionId,
}) => async (dispatch) => {
  const { data } = await api('POST /v0/route-permissions', {
    service,
    route,
    requestMethod,
    requiredPermissionId,
  })

  dispatch({ type: ROUTE_PERMISSION_ADD, data })

  return data
}

export const updateRoutePermission = (
  routePermissionId,
  { route, requiredPermissionId }
) => async (dispatch) => {
  const { data } = await api('PUT /v0/route-permissions/{routePermissionId}', {
    routePermissionId,
    route,
    requiredPermissionId,
  })

  dispatch({ type: ROUTE_PERMISSION_UPDATE, data, routePermissionId })

  return data
}

export const deleteRoutePermission = (routePermissionId) => async (
  dispatch
) => {
  await api('DELETE /v0/route-permissions/{routePermissionId}', {
    routePermissionId,
  })

  dispatch({ type: ROUTE_PERMISSION_REMOVE, routePermissionId })
}

export const getRoutePermission = (routePermissionId) => async (dispatch) => {
  const { data } = await api('GET /v0/route-permissions/{routePermissionId}', {
    routePermissionId,
  })

  dispatch({ type: ROUTE_PERMISSION_ADD, data })

  return data
}

export const getRoutePermissions = ({ service }) => async (dispatch) => {
  const { data } = await api('GET /v0/route-permissions{?service}', {
    service,
  })

  dispatch({ type: ROUTE_PERMISSION_ADD_BULK, data })

  return data
}
