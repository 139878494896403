import {
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import NavLink from 'components/Link/NavLink'
import Topbar from 'components/Topbar'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  initLoginWithPhone,
  loginWithEmail,
  loginWithPhone,
} from 'store/currentUser'
import * as Yup from 'yup'

function EmailLoginForm() {
  const toast = useToast()

  const [passwordViewable, setPasswordViewable] = useState(false)

  const defaultValues = useMemo(() => ({ email: '', password: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Enter valid email.').required('Required.'),
        password: Yup.string().required('Required.'),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ email, password }) => {
      try {
        await dispatch(loginWithEmail({ email, password }))
      } catch (err) {
        if (err.error) {
          toast({
            title: 'Failed to Login!',
            description: err.error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          console.error(err)
        }
      }
    },
    [dispatch, toast]
  )

  return (
    <Form form={form} spacing={4} onSubmit={onSubmit}>
      <FormInput name="email" label="Enter email address" />
      <Flex align="flex-end">
        <Box mr={2} flex={1}>
          <FormInput
            type={passwordViewable ? 'text' : 'password'}
            name="password"
            label="Enter password"
          />
        </Box>
        <IconButton
          icon={passwordViewable ? 'view' : 'view-off'}
          variant="outline"
          onClick={() => setPasswordViewable((state) => !state)}
        >
          Toggle
        </IconButton>
      </Flex>
      <Stack isInline spacing={2}>
        <Button as={NavLink} to={`/forgot-password`} variantColor="red">
          Forgot Password?
        </Button>
        <Button type="submit" variantColor="blue" flexGrow="1">
          Log In
        </Button>
      </Stack>
    </Form>
  )
}

function LoginWithPhone({ phone, loading }) {
  const toast = useToast()

  const defaultValues = useMemo(() => ({ code: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .matches(/^\d{4}$/, 'expected format: XXXX')
          .required(`required`),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ code }) => {
      try {
        await dispatch(loginWithPhone({ phone, code }))
      } catch (err) {
        if (err.error) {
          toast({
            title: 'Failed to Login!',
            description: err.error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          console.error(err)
        }
      }
    },
    [dispatch, phone, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack borderWidth="1px" rounded="lg" overflow="hidden" p={2} spacing={2}>
        <FormInput name="code" label={`OTP Code`} />

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting || loading}
          disabled={form.formState.isSubmitting || loading}
        >
          Login
        </Button>
      </Stack>
    </Form>
  )
}

function InitLoginWithPhone({ setPhone }) {
  const defaultValues = useMemo(() => ({ phone: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        phone: Yup.string()
          .matches(/^01\d{9}$/, 'expected format: 01XXXXXXXXX')
          .required(`required`),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ phone }) => {
      const formattedPhone = `88${phone}`
      await dispatch(initLoginWithPhone({ phone: formattedPhone }))
      setPhone(formattedPhone)
    },
    [dispatch, setPhone]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack overflow="hidden" p={2} spacing={2}>
        <FormInput name="phone" label={`Phone`} />

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          disabled={form.formState.isSubmitting}
        >
          Request OTP Code
        </Button>
      </Stack>
    </Form>
  )
}

function PhoneLoginForm() {
  const status = useSelector((state) => state.user.status)

  const [phone, setPhone] = useState(null)

  return !phone ? (
    <InitLoginWithPhone setPhone={setPhone} />
  ) : (
    <LoginWithPhone phone={phone} loading={status.loading} />
  )
}

function LoginPage({ location }) {
  const status = useSelector((state) => state.user.status)

  if (status.authed) {
    return <Redirect to={location.state ? location.state.from || '/' : '/'} />
  }

  return (
    <>
      <Topbar />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        {status.loading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <Tabs isFitted maxW="512px" width="100%" p={6}>
            <TabList mb="1em">
              <Tab>Email</Tab>
              <Tab>Phone</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={4} borderWidth="1px" rounded="lg">
                <EmailLoginForm />
              </TabPanel>
              <TabPanel p={4} borderWidth="1px" rounded="lg">
                <PhoneLoginForm />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Flex>
    </>
  )
}

export default LoginPage
