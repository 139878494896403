import { api } from 'api'
import { batch } from 'react-redux'
import { ROLE_ADD_BULK } from 'store/roles'

export const PERMISSION_ADD = 'PERMISSION_ADD'
export const PERMISSION_ADD_BULK = 'PERMISSION_ADD_BULK'
export const PERMISSION_UPDATE = 'PERMISSION_UPDATE'
export const PERMISSION_ROLES_SET = 'PERMISSION_ROLES_SET'

export const createPermission = ({
  scope,
  resource,
  action,
  description,
}) => async (dispatch) => {
  const { data } = await api('POST /v0/permissions', {
    scope,
    resource,
    action,
    description,
  })

  dispatch({ type: PERMISSION_ADD, data })

  return data
}

export const updatePermission = (
  permissionId,
  { scope, resource, action, description }
) => async (dispatch) => {
  const { data } = await api('PUT /v0/permissions/{permissionId}', {
    permissionId,
    scope,
    resource,
    action,
    description,
  })

  dispatch({ type: PERMISSION_UPDATE, data, permissionId })

  return data
}

export const getPermission = (permissionId) => async (dispatch) => {
  const { data } = await api('/v0/permissions/{permissionId}', {
    permissionId,
  })

  dispatch({ type: PERMISSION_ADD, data })

  return data
}

export const getAllPermissions = ({ scopeId } = {}) => async (dispatch) => {
  let data
  if (scopeId) {
    data = (
      await api('/v0/scopes/{scopeId}/permissions', {
        scopeId,
      })
    ).data
  } else {
    data = (await api('/v0/permissions')).data
  }

  dispatch({ type: PERMISSION_ADD_BULK, data })
  return data
}

export const getRolesForPermission = (permissionId) => async (
  dispatch,
  getState
) => {
  const { rolePermission } = getState()

  if (rolePermission.byPermission[permissionId]) {
    return {
      items: rolePermission.byPermission[permissionId].map((id) => ({ id })),
    }
  }

  const { data } = await api('/v0/permissions/{permissionId}/roles', {
    permissionId,
  })

  batch(() => {
    dispatch({ type: ROLE_ADD_BULK, data })
    dispatch({ type: PERMISSION_ROLES_SET, data, permissionId })
  })

  return data
}
