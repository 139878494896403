import { Heading, Stack } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { getAllRoles } from 'store/roles'
import RoleCreate from './Create'
import RoleListItem from './ListItem'

function RoleList() {
  const roles = useSelector((state) => state.roles)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllRoles())
  }, [dispatch])

  return (
    <Stack spacing={3}>
      <Flex
        mb={3}
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <Heading as="h2">Roles</Heading>
        </Box>
        <Box>
          <RoleCreate />
        </Box>
      </Flex>

      {roles.allIds.map((roleId) => (
        <RoleListItem key={roleId} roleId={roleId} />
      ))}
    </Stack>
  )
}

export default RoleList
