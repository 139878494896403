import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { api } from 'api'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { getUserById } from 'store/users'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  userType: 'SYSTEM_USER',
})

const getValidationSchema = () =>
  Yup.object({
    firstName: Yup.string().required(`required`),
    lastName: Yup.string().required(`required`),
    email: Yup.string().email().required(`required`),
    vaccountId: Yup.string().required(`required`),
    userType: Yup.string().required(`required`),
  })

function CreateUser({ setUserId }) {
  const dispatch = useDispatch()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const { data } = await api('POST /v0/users', formData)
        await dispatch(getUserById(data.id))
        setUserId(data.id)
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, setUserId, toast]
  )

  return (
    <Authorize permissions="auth:Role:create">
      <Button style={{ float: 'right' }} onClick={onOpen}>
        Create
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Create User</ModalHeader>
            <ModalCloseButton type="button" />

            <ModalBody>
              <FormInput name="firstName" label={`First Name`} />
              <FormInput name="lastName" label={`Last Name`} />
              <FormInput name="email" label={`Email`} />
              <FormInput name="vaccountId" label={`vAccount ID`} />

              <FormSelect
                name="userType"
                label={`User Type`}
                options={{ SYSTEM_USER: 'SYSTEM_USER' }}
              />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit">Create</Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default CreateUser
