import camelCaseKeys from 'camelcase-keys'
import { zipObject } from 'lodash-es'
import { batch } from 'react-redux'
import { emptyObject } from 'utils/defaults'
import { clearState } from 'utils/localStorage'

export const CURRENT_USER_LOGIN_REQUEST = 'CURRENT_USER_LOGIN_REQUEST'
export const CURRENT_USER_LOGOUT = 'CURRENT_USER_LOGOUT'
export const CURRENT_USER_UPDATE = 'CURRENT_USER_UPDATE'
export const CURRENT_USER_REMOVE = 'CURRENT_USER_REMOVE'

export const CURRENT_USER_PERMIT_SET_BULK = 'CURRENT_USER_PERMIT_SET_BULK'

export const initLoginWithPhone = (initLoginWithPhoneData) => async (
  _dispatch,
  _getState,
  { api }
) => {
  const { data } = await api('POST /v0/login/phone/init', {
    body: initLoginWithPhoneData,
  })

  return data
}

export const loginWithPhone = (loginWithPhoneData) => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    dispatch({ type: CURRENT_USER_LOGIN_REQUEST })

    const { data } = await api('POST /v0/login/phone', {
      body: loginWithPhoneData,
    })

    dispatch({
      type: CURRENT_USER_UPDATE,
      data: camelCaseKeys(data.user, { deep: true }),
    })

    return data
  } catch (err) {
    dispatch({ type: CURRENT_USER_REMOVE })
    throw err
  }
}

export const loginWithEmail = ({ email, password }) => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    dispatch({ type: CURRENT_USER_LOGIN_REQUEST })

    const { data } = await api('POST /v0/login/email', {
      body: { email, password },
    })

    dispatch({
      type: CURRENT_USER_UPDATE,
      data: camelCaseKeys(data.user, { deep: true }),
    })

    return data
  } catch (err) {
    dispatch({ type: CURRENT_USER_REMOVE })
    throw err
  }
}

export const requestPasswordReset = (payload) => async (
  _dispatch,
  _getState,
  { api }
) => {
  const { data } = await api('POST /v0/login/email/request-password-reset', {
    body: payload,
  })

  return data
}

export const resetPassword = ({
  password,
  passwordConfirmation,
  token,
}) => async (_dispatch, _getState, { api }) => {
  const { data } = await api('POST /v0/login/email/reset-password', {
    body: {
      password,
      passwordConfirmation,
      token,
    },
  })

  return data
}

export const logout = () => async (dispatch, _getState, { api }) => {
  const { data } = await api('POST /v0/logout')

  dispatch({ type: CURRENT_USER_LOGOUT })
  clearState()

  return data
}

export const checkAuthStatus = () => async (dispatch, _getState, { api }) => {
  try {
    const { data: user } = await api('/v0/user')
    const {
      data: { items: permissions },
    } = await api('/v0/user/permissions')

    const permits = zipObject(
      permissions.map((p) => p.id),
      permissions.map(() => 1)
    )

    batch(() => {
      dispatch({ type: CURRENT_USER_PERMIT_SET_BULK, data: permits })

      dispatch({
        type: CURRENT_USER_UPDATE,
        data: camelCaseKeys(user, { deep: true }),
      })
    })

    return user
  } catch (err) {
    dispatch({ type: CURRENT_USER_REMOVE })
  }
}

export const checkPermissions = ({ permissionIds }) => async (
  dispatch,
  getState,
  { api }
) => {
  const { user } = getState()

  if (!user.status.authed) {
    dispatch({ type: CURRENT_USER_REMOVE })

    return emptyObject
  }

  const permissionIdsToCheck = []

  for (const permissionId of permissionIds) {
    if (typeof user.permits[permissionId] === 'undefined') {
      permissionIdsToCheck.push(permissionId)
    }

    if (user.permits[permissionId] === null) {
      console.warn(`Recheck Permission(${permissionId})!`)
    }
  }

  if (!permissionIdsToCheck.length) {
    return user.permits
  }

  dispatch({
    type: CURRENT_USER_PERMIT_SET_BULK,
    data: zipObject(
      permissionIdsToCheck,
      permissionIdsToCheck.map(() => 0)
    ),
  })

  const { data } = await api('POST /v0/user/permissions/check', {
    permissionIds: permissionIdsToCheck,
  })

  dispatch({ type: CURRENT_USER_PERMIT_SET_BULK, data })

  return data
}
