import { keyBy, map, union } from 'lodash-es'
import { USER_ADD, USER_ADD_BULK, USER_ROLES_SET } from 'store/users'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  rolesById: emptyObject,
}

const usersReducer = (state = initialState, { type, userId: id, data }) => {
  switch (type) {
    case USER_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data[idKey]]),
      }
    case USER_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
      }
    case USER_ROLES_SET:
      return {
        ...state,
        rolesById: {
          ...state.rolesById,
          [id]: map(data.items, 'id'),
        },
      }
    default:
      return state
  }
}

export default usersReducer
