import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { Global } from '@emotion/core'
import RootErrorBoundary from 'components/RootErrorBoundary'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

function Root({ store, theme }) {
  return (
    <Provider store={store}>
      <RootErrorBoundary>
        <ThemeProvider theme={theme}>
          <CSSReset />
          <Global
            styles={{
              body: {
                fontSize: 14,
                overflow: 'hidden',
              },
              '#root': {
                height: '100vh',
                overflow: 'hidden',
              },
            }}
          />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </RootErrorBoundary>
    </Provider>
  )
}

export default Root
