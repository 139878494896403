import { Button, Text } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import RoleEdit from './Edit'

function RoleListItem({ roleId }) {
  const role = useSelector((state) => state.roles.byId[roleId])

  return (
    <Box
      sx={{
        p: 5,
        boxShadow: 'md',
        borderWidth: '1px',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Text>
            <Text as="strong">Name:</Text> {get(role, 'name')}
          </Text>
          <Text>
            <Text as="strong">Description:</Text> {get(role, 'description')}
          </Text>
        </Box>

        <Box>
          <RoleEdit roleId={roleId} />

          <Button as={Link} to={`/dash/roles/${roleId}`}>
            Open
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default RoleListItem
