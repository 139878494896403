import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateRole } from 'store/roles'
import * as Yup from 'yup'

const getDefaultValues = (role) => ({
  name: get(role, 'name', ''),
  description: get(role, 'description', ''),
})

const getValidationSchema = () =>
  Yup.object({
    name: Yup.string().required(`required`),
    description: Yup.string().required(`required`),
  })

function RoleEdit({ roleId }) {
  const role = useSelector((state) => state.roles.byId[roleId])

  const toast = useToast()

  const dispatch = useDispatch()

  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(role), [role])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const reset = form.reset
  useEffect(() => {
    reset(getDefaultValues(role))
  }, [reset, role])

  const onSubmit = useCallback(
    async ({ name, description }) => {
      try {
        await dispatch(updateRole(roleId, { name, description }))
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, roleId, toast]
  )

  return (
    <Authorize permissions="auth:Role:update">
      <Button onClick={onOpen}>Edit</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Edit Role: {roleId}</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <FormInput name="name" label={`Name`} />
              <FormInput name="description" label={`Description`} />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit">Save</Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default RoleEdit
