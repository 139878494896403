import { keyBy, map, union, without } from 'lodash-es'
import {
  ROUTE_PERMISSION_ADD,
  ROUTE_PERMISSION_ADD_BULK,
  ROUTE_PERMISSION_REMOVE,
  ROUTE_PERMISSION_UPDATE,
  ROUTE_SERVICE_ADD_BULK,
} from 'store/routePermissions'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  services: emptyArray,
}

const permissionsReducer = (
  state = initialState,
  { type, data, routePermissionId: id }
) => {
  switch (type) {
    case ROUTE_SERVICE_ADD_BULK:
      return {
        ...state,
        services: union(state.services, data.items),
      }
    case ROUTE_PERMISSION_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data[idKey]]),
        services: union(state.services, [data.service]),
      }
    case ROUTE_PERMISSION_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...data,
          },
        },
      }
    case ROUTE_PERMISSION_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        services: union(state.services, map(data.items, 'service')),
      }
    case ROUTE_PERMISSION_REMOVE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: undefined,
        },
        allIds: without(state.allIds, id),
      }
    default:
      return state
  }
}

export default permissionsReducer
