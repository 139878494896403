import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
} from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { logout } from 'store/currentUser'
import { useCurrentUser } from 'store/currentUser/hooks'
import { emptyArray } from 'utils/defaults'

function CurrentUserPopover() {
  const user = useCurrentUser()

  const dispatch = useDispatch()
  const logoutUser = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return user ? (
    <Popover>
      <PopoverTrigger>
        <Button variant="ghost">
          {get(user, 'firstName')} {get(user, 'lastName')}
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverArrow />
        <PopoverBody>
          <Box>
            <Text as="strong">Email: </Text>
            {get(user, 'email')}
          </Box>
          <Box>
            <Text as="strong">Phone: </Text>
            {get(user, 'phone')}
          </Box>
          <Box>
            <Text as="strong">Roles: </Text>
            {get(user, 'roles', emptyArray)
              .map(({ name }) => name)
              .join(', ')}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <Button width="full" variantColor="red" onClick={logoutUser}>
            Logout!
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  ) : null
}

export default CurrentUserPopover
