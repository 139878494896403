import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { usePermissionsForScope } from 'store/permissions/hooks'
import { createRoutePermission } from 'store/routePermissions'
import { useRouteServices } from 'store/routePermissions/hooks'
import { useScopes } from 'store/scopes/hooks'
import { emptyArray } from 'utils/defaults'
import { httpMethods } from 'utils/http-methods'
import * as Yup from 'yup'

const httpMethodOptions = zipObject(httpMethods, httpMethods)

const getDefaultValues = () => ({
  service: '',
  requestMethod: '',
  route: '',
  scopeId: '',
  requiredPermissionId: '',
})

const getValidationSchema = () =>
  Yup.object({
    service: Yup.string().required(`required`),
    requestMethod: Yup.string().oneOf(httpMethods).required(`required`),
    route: Yup.string().required(`required`),
    scopeId: Yup.string().required(`required`),
    requiredPermissionId: Yup.string().required(`required`),
  })

function RoutePermissionCreate() {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ service, requestMethod, route, requiredPermissionId }) => {
      try {
        await dispatch(
          createRoutePermission({
            service,
            requestMethod,
            route,
            requiredPermissionId,
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, toast]
  )

  const services = useRouteServices()
  const [newServices, setNewServices] = useState(emptyArray)
  const serviceOptions = useMemo(() => {
    const allServices = [...services, ...newServices]
    return zipObject(allServices, allServices)
  }, [newServices, services])
  const onNewService = useCallback((service) => {
    setNewServices((services) => services.concat(service))
  }, [])

  const scopes = useScopes()
  const scopeOptions = useMemo(() => {
    return zipObject(
      scopes.allIds,
      scopes.allIds.map((id) => get(scopes.byId[id], 'description'))
    )
  }, [scopes.allIds, scopes.byId])

  const scopeId = form.watch('scopeId')

  const permissions = usePermissionsForScope(scopeId)
  const permissionOptions = useMemo(() => {
    return zipObject(
      permissions.allIds,
      permissions.allIds.map((id) =>
        get(permissions.byId[id], 'description', id)
      )
    )
  }, [permissions.allIds, permissions.byId])

  return (
    <Authorize permissions="">
      <Button onClick={onOpen}>Create</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Form form={form} onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Route Permission</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Stack spacing={2}>
                <Box>
                  <FormSelect
                    name="service"
                    label={`Service`}
                    options={serviceOptions}
                    onCreateOption={onNewService}
                    SelectComponent={CreatableSelect}
                  />
                </Box>

                <Box>
                  <FormSelect
                    name="requestMethod"
                    label={`HTTP Method`}
                    options={httpMethodOptions}
                  />
                </Box>

                <FormInput name="route" label={`Route`} />

                <Box>
                  <FormSelect
                    name="scopeId"
                    label={`Permission Scope`}
                    options={scopeOptions}
                  />
                </Box>

                <Box>
                  <FormSelect
                    name="requiredPermissionId"
                    label={`Required Permission`}
                    options={permissionOptions}
                  />
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <FormButton type="submit">Create</FormButton>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

export default RoutePermissionCreate
