import { combineReducers } from 'redux'
import { getPaginationReducer } from 'store/helpers'
import {
  USER_BY_ROLE_PAGE_ADD,
  USER_BY_ROLE_PAGE_REMOVE,
  USER_BY_ROLE_PAGE_REQUEST,
  USER_BY_ROLE_PAGINATION_PURGE,
} from 'store/users'

const userByRole = getPaginationReducer({
  idKey: 'id',
  ADD: USER_BY_ROLE_PAGE_ADD,
  REMOVE: USER_BY_ROLE_PAGE_REMOVE,
  REQUEST: USER_BY_ROLE_PAGE_REQUEST,
  PURGE: USER_BY_ROLE_PAGINATION_PURGE,
})

const paginationReducer = combineReducers({
  userByRole,
})

export default paginationReducer
