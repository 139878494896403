import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormMultiSelect from 'components/Form/MultiSelect'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createFilter } from 'react-select'
import { Box } from 'reflexbox'
import { setUserScopesById } from 'store/users'
import { emptyArray } from 'utils/defaults'
import * as Yup from 'yup'
import FormButton from 'components/Form/Button'

const getDefaultValues = (userScopeIds) => ({
  scopeIds: userScopeIds,
})

const getValidationSchema = () => {
  return Yup.object({
    scopeIds: Yup.array().of(Yup.string()),
  })
}

function UserScopes({ userId, ...props }) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const user = useSelector((state) => get(state.users.byId, userId))

  const scopes = useSelector((state) => state.scopes)

  const userScopeIds = useSelector((state) =>
    get(state.userScope.byUser, userId, emptyArray)
  )

  const scopeOptions = useMemo(() => {
    return zipObject(
      scopes.allIds,
      scopes.allIds.map((id) => get(scopes.byId[id], 'description', id))
    )
  }, [scopes.allIds, scopes.byId])

  const getFilterOption = useCallback(
    (selectedScopeIds) => (...args) => {
      return (
        !selectedScopeIds.includes(args[0].value) && createFilter()(...args)
      )
    },
    []
  )

  const defaultValues = useMemo(() => getDefaultValues(userScopeIds), [
    userScopeIds,
  ])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    if (isOpen) {
      formReset(defaultValues)
    }
  }, [defaultValues, formReset, isOpen])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ scopeIds }) => {
      await dispatch(setUserScopesById(userId, { scopeIds }))
    },
    [dispatch, userId]
  )

  const selectedScopeIds = form.watch('scopeIds')

  return (
    <Box {...props}>
      <Text>
        <Text as="strong">Scopes: </Text>
        <Button
          size="xs"
          variant="outline"
          variantColor="blue"
          onClick={onOpen}
        >
          Update
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>Set Scopes for {get(user, 'FULL_NAME')}</ModalHeader>

              <ModalCloseButton />

              <ModalBody>
                <FormMultiSelect
                  name="scopeIds"
                  options={scopeOptions}
                  filterOption={getFilterOption(selectedScopeIds)}
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  type="button"
                  variantColor="blue"
                  mr={3}
                  onClick={onClose}
                >
                  Close
                </Button>
                <FormButton type="submit" variantColor="green">
                  Set Scopes
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Text>
      <Stack spacing={3} isInline mt={2}>
        {userScopeIds.map((scopeId) => (
          <Tag key={scopeId}>
            {get(scopes.byId[scopeId], 'description', scopeId)}
          </Tag>
        ))}
      </Stack>
    </Box>
  )
}

export default UserScopes
