import { api } from 'api'

export const ROLE_ADD = 'ROLE_ADD'
export const ROLE_ADD_BULK = 'ROLE_ADD_BULK'
export const ROLE_UPDATE = 'ROLE_UPDATE'
export const ROLE_SET_ALL = 'ROLE_SET_ALL'
export const ROLE_PERMISSIONS_SET = 'ROLE_PERMISSIONS_SET'
export const ROLE_SCOPES_SET = 'ROLE_SCOPES_SET'

export const createRole = ({ name, description }) => async (dispatch) => {
  const { data } = await api('POST /v0/roles', {
    name,
    description,
  })

  dispatch({ type: ROLE_ADD, data })

  return data
}

export const updateRole = (roleId, { name, description }) => async (
  dispatch
) => {
  const { data } = await api('PUT /v0/roles/{roleId}', {
    roleId,
    name,
    description,
  })

  dispatch({ type: ROLE_UPDATE, data })

  return data
}

export const getAllRoles = () => async (dispatch) => {
  const { data } = await api('/v0/roles')

  dispatch({ type: ROLE_SET_ALL, data })

  return data
}

export const getRole = (roleId) => async (dispatch) => {
  const { data } = await api(`/v0/roles/{roleId}`, {
    roleId,
  })

  dispatch({ type: ROLE_ADD, data })

  return data
}

export const getPermissionsForRole = (roleId) => async (dispatch, getState) => {
  const { rolePermission } = getState()

  if (rolePermission.byRole[roleId]) {
    return {
      items: rolePermission.byRole[roleId].map((id) => ({ id })),
    }
  }

  const { data } = await api('/v0/roles/{roleId}/permissions', {
    roleId,
  })

  dispatch({ type: ROLE_PERMISSIONS_SET, data, roleId })

  return data
}

export const setPermissionsForRole = (roleId, { permissionIds }) => async (
  dispatch
) => {
  const { data } = await api('PUT /v0/roles/{roleId}/permissions', {
    roleId,
    permissionIds,
  })

  dispatch({ type: ROLE_PERMISSIONS_SET, data, roleId })

  return data
}

export const getScopesForRole = (roleId) => async (dispatch, getState) => {
  const { roleScope } = getState()

  if (roleScope.byRole[roleId]) {
    return {
      items: roleScope.byRole[roleId].map((id) => ({ id })),
    }
  }

  const { data } = await api('/v0/roles/{roleId}/scopes', {
    roleId,
  })

  dispatch({ type: ROLE_SCOPES_SET, data, roleId })

  return data
}

export const setScopesForRole = (roleId, { scopeIds }) => async (dispatch) => {
  const { data } = await api('PUT /v0/roles/{roleId}/scopes', {
    roleId,
    scopeIds,
  })

  dispatch({ type: ROLE_SCOPES_SET, data, roleId })

  return data
}
