import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { revokeUserRolesByUserId } from 'store/users'

function RevokeUserRolesButton({ userId }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const toast = useToast()

  const dispatch = useDispatch()
  const onSubmit = useCallback(async () => {
    try {
      await dispatch(revokeUserRolesByUserId(userId))
      onClose()
    } catch (err) {
      handleAPIError(err, { toast })
    }
  }, [dispatch, onClose, userId, toast])

  return (
    <Authorize permissions="auth:UserRole:set">
      <Button colorScheme="red" onClick={onOpen} size="sm">
        Revoke Roles
      </Button>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Revoke all user roles
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text fontSize={3} mb={2}>
              Are you sure you want to revoke all roles / permissions for this user?
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>Cancel</Button>
            <Button variantColor="red" onClick={onSubmit} ml={3}>
              Revoke
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Authorize>
  )
}

export default RevokeUserRolesButton
