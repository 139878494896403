import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getRole } from 'store/roles'
import RolePermissionsTab from './Tabs/Permissions'
import RoleScopesTab from './Tabs/Scopes'

function RoleView() {
  const { roleId } = useParams()

  const role = useSelector((state) => state.roles.byId[roleId])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!role && roleId) {
      dispatch(getRole(roleId))
    }
  }, [dispatch, role, roleId])

  return (
    <>
      <Heading as="h2" mb={5}>
        <Text as="span" fontStyle="italic">
          Role:
        </Text>{' '}
        {get(role, 'name')}
      </Heading>

      <Tabs>
        <TabList mb={5}>
          <Tab>Permissions</Tab>
          <Tab>Scopes</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <RolePermissionsTab roleId={roleId} />
          </TabPanel>
          <TabPanel>
            <RoleScopesTab roleId={roleId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default RoleView
