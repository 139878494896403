import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { createRole } from 'store/roles'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  name: '',
  description: '',
})

const getValidationSchema = () =>
  Yup.object({
    name: Yup.string().required(`required`),
    description: Yup.string().required(`required`),
  })

function RoleCreate() {
  const toast = useToast()

  const dispatch = useDispatch()

  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const onSubmit = useCallback(
    async ({ name, description }) => {
      try {
        await dispatch(createRole({ name, description }))
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, toast]
  )

  return (
    <Authorize permissions="auth:Role:create">
      <Button onClick={onOpen}>Create</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Create New Role</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <FormInput name="name" label={`Name`} />
              <FormInput name="description" label={`Description`} />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit">Create</Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default RoleCreate
