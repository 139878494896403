import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RoutePermissionList from './List'

function RoutePermissionsPage() {
  return (
    <Switch>
      <Route
        exact
        path="/dash/route-permissions"
        component={RoutePermissionList}
      />
    </Switch>
  )
}

export default RoutePermissionsPage
