import { IconButton, Stack } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import React from 'react'
import { Box } from 'reflexbox'

const items = [
  {
    title: 'Permissions',
    link: '/dash/permissions',
    permissions: 'auth:Permission:read',
  },
  {
    title: 'Roles',
    link: '/dash/roles',
    permissions: 'auth:Role:read',
  },
  {
    title: 'Scopes',
    link: '/dash/scopes',
    permissions: 'auth:Scope:read',
  },
  {
    title: 'Route Permissions',
    link: '/dash/route-permissions',
    permissions: 'auth:Permission:read',
  },
  {
    title: 'Find User',
    link: '/dash/users/find-user',
    permissions: 'auth:UserRole:read',
  },
  {
    title: 'List by Role',
    link: '/dash/users/by-role',
    permissions: 'auth:UserRole:read',
  },
]

function Sidebar({ isOpen, onToggle }) {
  return (
    <Box
      flexGrow="0"
      width="sidebar"
      bg="gray.50"
      p={6}
      sx={{
        transition: '0.2s',
        borderRight: '2px solid',
        borderRightColor: 'gray.200',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: ({ sizes }) => (isOpen ? 0 : `calc(-${sizes.sidebar} + 1rem)`),
        height: ({ sizes }) => `calc(100% - ${sizes.navbar})`,
        mt: ({ sizes }) => sizes.navbar,
      }}
    >
      <IconButton
        icon="chevron-left"
        variantColor="red"
        size="sm"
        isRound
        onClick={onToggle}
        position="absolute"
        right="-1rem"
        transition="0.2s"
        transform={`rotate(${isOpen ? '0' : '180'}deg)`}
        aria-label={`${isOpen ? 'Close' : 'Open'} Sidebar`}
      />
      <Stack spacing={2}>
        {items.map(({ title, link, permissions }) =>
          permissions ? (
            <Authorize key={link} permissions={permissions}>
              <NavLink to={link}>{title}</NavLink>
            </Authorize>
          ) : (
            <NavLink key={link} to={link}>
              {title}
            </NavLink>
          )
        )}
      </Stack>
    </Box>
  )
}

export default Sidebar
