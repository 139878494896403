import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createPermission } from 'store/permissions'
import { getAllScopes } from 'store/scopes'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  scope: '',
  resource: '',
  action: '',
  description: '',
})

const getValidationSchema = () =>
  Yup.object({
    scope: Yup.string().required(`required`),
    resource: Yup.string().required(`required`),
    action: Yup.string().required(`required`),
    description: Yup.string().required(`required`),
  })

function PermissionCreate() {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllScopes())
  }, [dispatch])

  const scopes = useSelector((state) => state.scopes)

  const scopeOptions = useMemo(() => {
    return zipObject(
      scopes.allIds,
      scopes.allIds.map((id) => get(scopes.byId[id], 'description'))
    )
  }, [scopes.allIds, scopes.byId])

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const onSubmit = useCallback(
    async ({ scope, resource, action, description }) => {
      try {
        await dispatch(
          createPermission({ scope, resource, action, description })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, toast]
  )

  return (
    <Authorize permissions="auth:Permission:create">
      <Button onClick={onOpen}>Create</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Form form={form} onSubmit={onSubmit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Permission</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <FormSelect
                name="scope"
                label={`Scope Name`}
                options={scopeOptions}
              />
              <FormInput name="resource" label={`Resource Name`} />
              <FormInput name="action" label={`Action Name`} />
              <FormInput name="description" label={`Description`} />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button type="submit">Create</Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

export default PermissionCreate
