import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ScopeList from './List'
import ScopeView from './View'

function ScopesPage() {
  return (
    <Switch>
      <Route exact path="/dash/scopes" component={ScopeList} />
      <Route path="/dash/scopes/:scopeId" component={ScopeView} />
    </Switch>
  )
}

export default ScopesPage
