import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import NavLink from 'components/Link/NavLink'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { requestPasswordReset, resetPassword } from 'store/currentUser'
import * as Yup from 'yup'

function ResetPassword({ token }) {
  const toast = useToast()
  const history = useHistory()

  const defaultValues = useMemo(
    () => ({ password: '', passwordConfirmation: '' }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(8, 'Should be atleast 8 characters.')
          .required('Required.'),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], "Passwords don't match")
          .required('Required.'),
      }),
    []
  )

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ password, passwordConfirmation }) => {
      try {
        await dispatch(
          resetPassword({
            password,
            passwordConfirmation,
            token,
          })
        )
        toast({
          title: 'Password has been reset successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        history.push('/login')
      } catch (err) {
        if (err.error) {
          toast({
            title: 'Failed to reset password.',
            description: err.error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          console.error(err)
        }
      }
    },
    [dispatch, toast, token, history]
  )
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  return (
    <>
      <Box>
        <Heading mb={3} color="primary.500">
          Reset password
        </Heading>
        <Text my={2}>Enter a new password to set to your account</Text>
      </Box>
      <Form form={form} spacing={4} onSubmit={onSubmit}>
        <FormInput type="password" name="password" label="Enter new password" />
        <FormInput
          type="password"
          name="passwordConfirmation"
          label="Confirm new password"
        />
        <Button variantColor="blue" type="submit">
          SUBMIT
        </Button>
        <Button as={NavLink} to={`/login`} variantColor="red" variant="link">
          REMEMBER? LOG IN TO YOUR ACCOUNT
        </Button>
      </Form>
    </>
  )
}

function RequestPasswordReset({ urlTemplate }) {
  const status = useSelector((state) => state.user.status)

  const toast = useToast()

  const defaultValues = useMemo(() => ({ email: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Enter valid email.').required('Required.'),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        await dispatch(
          requestPasswordReset({
            email,
            urlTemplate,
          })
        )
        toast({
          title: 'An email has been sent.',
          description:
            'An email with a link to reset your password has been sent.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } catch (err) {
        if (err.error) {
          toast({
            title: 'Failed to request password reset.',
            description: err.error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          console.error(err)
        }
      }
    },
    [dispatch, toast, urlTemplate]
  )

  return (
    <>
      <Box>
        <Heading mb={3} color="primary.500">
          Recover password
        </Heading>
      </Box>
      <Form form={form} spacing={4} onSubmit={onSubmit}>
        <FormInput name="email" label="Email address" />
        <Stack isInline>
          <Button
            as={NavLink}
            to={`/login`}
            variantColor="red"
            variant="outline"
          >
            Log In
          </Button>
          <Button
            variantColor="blue"
            type="submit"
            flexGrow="1"
            isLoading={status.loading}
            isDisabled={status.loading}
          >
            Request Password Reset Email
          </Button>
        </Stack>
      </Form>
    </>
  )
}

function ForgotPassword() {
  const { token } = useParams()

  const location = useLocation()

  const strippedPathname = useMemo(
    () => location.pathname.split('/').join(''),
    [location.pathname]
  )

  const status = useSelector((state) => state.user.status)

  if (status.authed) {
    return <Redirect to={location.state ? location.state.from : '/'} />
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      maxWidth="512px"
      mx="auto"
      p={4}
    >
      <Box width="100%" borderWidth="1px" p={4}>
        {token ? (
          <ResetPassword token={token} />
        ) : (
          <RequestPasswordReset
            urlTemplate={`${window.location.origin.toString()}/${strippedPathname}/{token}`}
          />
        )}
      </Box>
    </Flex>
  )
}

export default ForgotPassword
