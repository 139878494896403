import { keyBy, map, union } from 'lodash-es'
import {
  SCOPE_ADD,
  SCOPE_PERMISSIONS_SET,
  SCOPE_ROLES_SET,
  SCOPE_SET_ALL,
  SCOPE_UPDATE,
} from 'store/scopes'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  permissionsById: emptyObject,
  rolesById: emptyObject,
}

const scopesReducer = (
  state = initialState,
  { type, data, scopeId: id, roleId }
) => {
  switch (type) {
    case SCOPE_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: { ...data },
        },
        allIds: union(state.allIds, [data[idKey]]),
      }
    case SCOPE_SET_ALL:
      return {
        ...state,
        byId: keyBy(data.items, idKey),
        allIds: map(data.items, idKey),
      }
    case SCOPE_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: { ...data },
        },
      }
    case SCOPE_PERMISSIONS_SET:
      return {
        ...state,
        permissionsById: {
          ...state.permissionsById,
          [id]: map(data.items, 'id'),
        },
      }
    case SCOPE_ROLES_SET:
      return {
        ...state,
        rolesById: {
          ...state.rolesById,
          [id]: map(data.items, 'id'),
        },
      }
    default:
      return state
  }
}

export default scopesReducer
