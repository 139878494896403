import { Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import RoleListItem from 'pages/roles/ListItem'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'reflexbox'
import { getRolesForScope } from 'store/scopes'
import { emptyArray } from 'utils/defaults'

function ScopeRolesTab({ scopeId }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRolesForScope(scopeId))
  }, [dispatch, scopeId])

  const scopeRoleIds = useSelector((state) =>
    get(state.roleScope.byScope, scopeId, emptyArray)
  )

  return (
    <Stack spacing={5}>
      <Box>
        {scopeRoleIds.map((roleId) => (
          <RoleListItem key={roleId} roleId={roleId} />
        ))}
      </Box>
    </Stack>
  )
}

export default ScopeRolesTab
