import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getScope } from 'store/scopes'
import ScopePermissionsTab from './Tabs/Permissions'
import ScopeRolesTab from './Tabs/Roles'

function ScopeView() {
  const { scopeId } = useParams()

  const scope = useSelector((state) => state.scopes.byId[scopeId])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!scope && scopeId) {
      dispatch(getScope(scopeId))
    }
  }, [dispatch, scope, scopeId])

  return (
    <>
      <Heading as="h2" mb={5}>
        <Text as="span" fontStyle="italic">
          Scope:
        </Text>{' '}
        {get(scope, 'id')}
      </Heading>

      <Tabs>
        <TabList mb={5}>
          <Tab>Permissions</Tab>
          <Tab>Roles</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ScopePermissionsTab scopeId={scopeId} />
          </TabPanel>
          <TabPanel>
            <ScopeRolesTab scopeId={scopeId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default ScopeView
