import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllScopes } from 'store/scopes'

export function useScopes() {
  const scopes = useSelector((state) => state.scopes)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllScopes())
  }, [dispatch])

  return scopes
}
