import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRoutePermission,
  getRoutePermissions,
  getRouteServices,
} from 'store/routePermissions'

export function useRouteServices() {
  const services = useSelector((state) => state.routePermissions.services)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRouteServices())
  }, [dispatch])

  return services
}

export function useRoutePermissionsForService(service) {
  const { byId, allIds } = useSelector((state) => state.routePermissions)

  const permissions = useMemo(() => {
    return {
      byId,
      allIds: allIds.filter((id) => byId[id]?.service === service),
    }
  }, [allIds, byId, service])

  const dispatch = useDispatch()

  useEffect(() => {
    if (service) {
      dispatch(getRoutePermissions({ service }))
    }
  }, [dispatch, service])

  return permissions
}

export function useRoutePermission(routePermissionId) {
  const data = useSelector(
    (state) => state.routePermissions.byId[routePermissionId]
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (!data && routePermissionId) {
      dispatch(getRoutePermission(routePermissionId))
    }
  }, [data, dispatch, routePermissionId])

  return data
}
