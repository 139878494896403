const localStorageNamespace = 'shopup:auth'
const localStorageNamespacePattern = new RegExp(`^${localStorageNamespace}`)

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(
      `${localStorageNamespace}:${key}`
    )
    return serializedState !== null ? JSON.parse(serializedState) : undefined
  } catch (err) {
    console.error(err)
  }
}

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(`${localStorageNamespace}:${key}`, serializedState)
  } catch (err) {
    console.error(err)
  }
}

export const clearState = () => {
  const keys = Object.keys(localStorage)
  for (const key of keys) {
    if (localStorageNamespacePattern.test(key)) {
      localStorage.removeItem(key)
    }
  }
}
