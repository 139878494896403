import { map } from 'lodash-es'
import { SCOPE_USERS_SET } from 'store/scopes'
import { USER_SCOPES_SET } from 'store/users'
import { emptyObject } from 'utils/defaults'

const initialState = {
  byUser: emptyObject,
  byScope: emptyObject,
}

const userScopeReducer = (
  state = initialState,
  { type, data, userId, scopeId }
) => {
  switch (type) {
    case SCOPE_USERS_SET:
      return {
        byUser: initialState.byUser,
        byScope: {
          ...state.byScope,
          [scopeId]: map(data.items, 'ID'),
        },
      }
    case USER_SCOPES_SET:
      return {
        byUser: {
          ...state.byUser,
          [userId]: map(data.items, 'id'),
        },
        byScope: initialState.byScope,
      }
    default:
      return state
  }
}

export default userScopeReducer
